var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseBankCreate" },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          _vm.plaidLinkVisible
            ? _c("plaid-link", {
                on: {
                  "adding-troublesome-bank": function($event) {
                    _vm.troublesomeBank = $event
                  },
                  "bank-added": _vm.addBank
                }
              })
            : _vm._e(),
          _vm.showUpdateBankModal
            ? _c("update-bank-modal", {
                attrs: {
                  bank: _vm.bank,
                  editing: false,
                  "troublesome-bank": _vm.troublesomeBank
                },
                on: {
                  "bank-updated": function($event) {
                    return _vm.$emit("bank-added")
                  },
                  close: function($event) {
                    return _vm.$emit("close")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }