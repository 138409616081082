<template>
  <div class="DashboardClientBanking column">
    <base-alert
      v-if="activeBankVerificationExpired"
      class="mb-24"
      :disable-close="true"
    >
      <template v-slot:message>
        <label class="fc-white">
          Your active bank information has expired. Please update immediately so your funds are not delayed.
        </label>
      </template>
    </base-alert>

    <label class="fs-16 fw-med">Bank Accounts</label>
    <label class="fc-light fs-14 mb-24">
      This is where we show the bank account(s) you have linked to Bobtail.
      <a
        @click="$set(personalizedExplanationModal, 'visible', true)"
        class="fc-blue fs-14"
        data-cy="personalized-modal-button"
      >
        Click here
      </a>
      to learn more about why your bank account looks like the way it does.
    </label>

    <div class="row row--wrap mb-24">
      <base-button
        v-if="userIsClient"
        @click="bankCreateVisible = true"
        class="bg-green fc-white mr-10"
        data-cy="add-bank-button"
      >
        <i class="fa fa-add-circle fa-15" />
        New Bank Account
      </base-button>
      <button
        @click="toggleHiddenBanksCallback"
        class="DashboardClientBanking__archive-btn bg-trans fc-blue"
      >
        {{ !showArchivedBanks ? 'Show' : 'Hide' }} Archived Banks
      </button>
    </div>

    <div
      v-if="banks.length"
      class="column"
    >
      <div
        class="row row--wrap"
        data-cy="banks"
      >
        <base-bank
          v-for="bank in banks"
          :key="bank.id"
          @bank-updated="getBanks"
          @done-adding-bank="loading = false"
          @started-adding-bank="loading = true"
          :bank-account="bank"
          :data-cy="`base-bank-${bank.id}`"
          :user-is-client="userIsClient"
        />
      </div>
    </div>

    <transition name="Transition__opacity-fade">
      <div
        v-if="loading"
        class="
          DashboardClientBanking__loading-indicator-container row row--align-center
          row--justify-center
        "
      >
        <i class="fa fa-processing fa-spin fs-60" />
      </div>
    </transition>

    <base-personalized-banking-modal
      v-if="personalizedExplanationModal.visible"
      @close="personalizedExplanationModal.visible = false"
      :active="personalizedExplanationModal.active"
      :no-name="personalizedExplanationModal.noName"
      :pending-verification="personalizedExplanationModal.pendingVerification"
      :pending-micro-deposits="personalizedExplanationModal.pendingMicroDeposits"
      :requires-wire-routing-number="personalizedExplanationModal.requiresWireRoutingNumber"
      :verification-expired="personalizedExplanationModal.verificationExpired"
    />

    <base-bank-create
      v-if="bankCreateVisible"
      @bank-added="bankAddedCallback"
      @close="bankCreateVisible = false"
      @done-adding-bank="loading = false"
      @started-adding-bank="loading = true"
      :client-id="id"
    />
  </div>
</template>

<script>
// Helpers
import {
  Client,
  ClientBankAccount
} from '../../utils/api'
// Components
import BaseAlert from '../../components/base-alert.vue'
import BaseBank from '../../components/base-bank.vue'
import BaseBankCreate from '../../components/base-bank-create.vue'
import BaseButton from '../../components/base-button.vue'
import BasePersonalizedBankingModal from '../../components/base-personalized-banking-modal.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

export default {
  name: 'DashboardClientBanking',

  components: {
    BaseAlert,
    BaseBank,
    BaseBankCreate,
    BaseButton,
    BasePersonalizedBankingModal,
  },

  mixins: [ValidationMixin],

  props: {
    autoTriggerNewBank: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
    userIsEmployee: {
      type: Boolean,
      required: true,
    },
    plaidUrl: {
      type: String,
      default: process.env.VUE_APP_PLAID_URL
    },
    selectAccount: Boolean,
    token: {
      type: String,
      required: false,
      default: null,
    },
    product: {
      type: String,
      default: 'auth'
    },
  },

  async created () {
    // Used to display and update banks
    await this.getBanks()
    // Used to update client when closing Capitol One alert
    await this.getClient()
    // Controls display of loading indicator
    this.loading = false
  },

  mounted () {
    // Auto-trigger new bank if user came from bank required notification
    if (this.autoTriggerNewBank) this.bankCreateVisible = true
  },

  data () {
    return {
      bankCreateVisible: false,
      banks: [],
      client: null,
      loading: true,
      personalizedExplanationModal: {
        active: false,
        noName: false,
        pendingMicroDeposits: false,
        pendingVerification: false,
        requiresWireRoutingNumber: false,
        verificationExpired: false,
        visible: false,
      },
      showArchivedBanks: false,
    }
  },

  computed: {
    activeBankVerificationExpired () {
      if (!this.banks.filter(bank => bank.status === 'active').length) return false

      const activeBank = this.banks.filter(bank => bank.status === 'active')

      if (!activeBank.length) return false

      if (activeBank[0].plaid_verification_status === 'ITEM_LOGIN_REQUIRED') return true
      return false
    },
  },

  methods: {
    async bankAddedCallback () {
      this.bankCreateVisible = false
      await this.getBanks()
    },

    getActionText (plaid_verification_status) {
      if (plaid_verification_status === 'pending_manual_verification') {
        return 'VERIFY MICRODEPOSITS'
      }
      if (plaid_verification_status === 'verification_expired') {
        return 'UPDATE'
      }
    },

    async getBanks () {
      this.loading = true
      this.progressStart()

      try {
        this.banks = (await ClientBankAccount.queryList({
          client_id: this.id,
          in___status: !this.showArchivedBanks ? ['active', 'inactive'] : ['active', 'inactive', 'hidden'],
        })).data.rows
        this.personalizeBankExplanation()
        // Mainly, this serves to display the updated bank(s) after calling getBanks
        // when a bank is successfully added; otherwise, it doesn't affect anything else
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Banking "Get Banks"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s bank account' })
      }

      this.loading = false
    },

    async getClient () {
      try {
        this.client = (await Client.get(this.id)).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Banking "Get Client"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client' })
      }
    },

    // There is a direct copy of this function at user-activity.vue:504
    personalizeBankExplanation () {
      // Look through this.banks and set personalizedExplanationModal values
      // according to the state of the banks
      this.banks.forEach(bank => {
        if (bank.status === 'active') this.$set(this.personalizedExplanationModal, 'active', true)

        if (!bank.bank_account_owner_name) this.$set(this.personalizedExplanationModal, 'noName', true)

        if (bank.plaid_verification_status === 'pending_automatic_verification') {
          this.$set(this.personalizedExplanationModal, 'pendingVerification', true)
        }

        if (bank.plaid_verification_status === 'ITEM_LOGIN_REQUIRED') {
          this.$set(this.personalizedExplanationModal, 'verificationExpired', true)
        }

        if (bank.plaid_verification_status === 'pending_manual_verification') {
          this.$set(this.personalizedExplanationModal, 'pendingMicroDeposits', true)
        }

        if (!bank.confirmed_wire && bank.modern_treasury_external_account_id) {
          this.$set(this.personalizedExplanationModal, 'requiresWireRoutingNumber', true)
        }
      })
    },

    async toggleHiddenBanksCallback () {
      this.showArchivedBanks = !this.showArchivedBanks
      await this.getBanks()
    },
  },
}
</script>

<style lang="sass">
.DashboardClientBanking
  position: relative

  .BaseBank
    &:last-child
      margin-bottom: 0

  &__archive-btn
    // Make sure it's at least 38px tall (usability)
    padding: rem(12px) 0

  &__loading-indicator-container
    background-color: rgba($background, 0.85)
    height: 100%
    left: 0
    min-height: rem(494px)
    position: absolute
    top: 0
    width: 100%

  @media #{$tablet-landscape-and-down}
    margin-top: rem(15px)

</style>
