<template>
  <base-modal class="BasePersonalizedBankingModal">
    <template v-slot:label>Why does your bank account look like that?</template>
    <template v-slot:input>
      <div class="BasePersonalizedBankingModal__scrolling-help-content">
        <p class="fs-14">
          A bank account can have multiple different "states" and depending on the state, you may
          need to do something to get your bank account active and accepting funds from Bobtail.
        </p>

        <hr class="Divider mb-24 mt-24">

        <div
          v-if="active"
          class="BasePersonalizedBankingModal__help-bank"
        >
          <label class="row fw-med mb-8">Active</label>
          <p class="fs-14 row mb-8">
            If you're bank looks like this, then you're ready to accept money from Bobtail!
          </p>
          <base-bank
            :bank-account="{
              bank_name: 'Bobtail Bank',
              bank_account_full_name: 'Bobtail Bank Full Name',
              bankInfo: {
                account: '123456789012'
              },
              confirmed_wire: true,
              modern_treasury_external_account_id: 14324524,
              plaid_verification_status: null,
              status: 'active',
            }"
            :prevent-click="true"
            :user-is-client="true"
          />
        </div>

        <div
          v-if="noName"
          class="BasePersonalizedBankingModal__help-bank"
        >
          <label class="row fw-med mb-8">No Name on Account</label>
          <p class="fs-14 row mb-8">
            If your bank looks like this, for some reason, we didn't receive the name on your
            account and you can't receive wire transfers.
            First, in your banking dashboard, click on the bank then click "edit account details."
            Second, input the exact name on your bank account in the "name on account" input field.
            Lastly, click update.
          </p>
          <base-bank
            :bank-account="{
              bank_account_full_name: null,
              bankInfo: {
                account: '123456789012'
              },
              bank_name: 'Bobtail Bank',
              confirmed_wire: true,
              modern_treasury_external_account_id: 14324524,
              plaid_verification_status: null,
              status: null,
            }"
            :prevent-click="true"
            :user-is-client="true"
          />
        </div>

        <div
          v-if="pendingVerification"
          class="BasePersonalizedBankingModal__help-bank"
        >
          <label class="row fw-med mb-8">Pending Verification</label>
          <p class="fs-14 row mb-8">
            Pending verification means your bank is undergoing an automated verificatino process.
            Check back later and you'll most likely find it updated!
          </p>
          <base-bank
            :bank-account="{
              bank_name: 'Bobtail Bank',
              bank_account_full_name: 'Bobtail Bank Full Name',
              bankInfo: {
                account: '123456789012'
              },
              confirmed_wire: true,
              modern_treasury_external_account_id: 14324524,
              plaid_verification_status: 'pending_automatic_verification',
              status: null,
            }"
            :prevent-click="true"
            :user-is-client="true"
          />
        </div>

        <div
          v-if="verificationExpired"
          class="BasePersonalizedBankingModal__help-bank"
        >
          <label class="row fw-med mb-8">Verification Expired</label>
          <p class="fs-14 row mb-8">
            Verification expired means your bank requires you to re-authenticate your bank
            account every so often, so if your bank looks like this, click the "update" button on
            the bank account.
          </p>
          <base-bank
            :bank-account="{
              bank_name: 'Bobtail Bank',
              bank_account_full_name: 'Bobtail Bank Full Name',
              bankInfo: {
                account: '123456789012'
              },
              confirmed_wire: true,
              modern_treasury_external_account_id: 14324524,
              plaid_verification_status: 'verification_expired',
              status: null,
            }"
            :prevent-click="true"
            :user-is-client="true"
          />
        </div>

        <div
          v-if="pendingMicroDeposits"
          class="BasePersonalizedBankingModal__help-bank"
        >
          <label class="row fw-med mb-8">Pending Micro-deposits</label>
          <p class="fs-14 row mb-8">
            <!-- eslint-disable-next-line max-len -->
            Pending micro-deposits means Plaid has sent you two very small transactions (less than $1) to verify the routing numbers of your account. First, do not guess these amounts. It will lock out your account permanently if you fail three times. Second, you need to find those transaction amounts in your bank transactions. Third, within your banking dashboard, click on the respective bank and click "Verify Micro-deposits" and enter the amounts.
          </p>
          <base-bank
            :bank-account="{
              bank_name: 'Bobtail Bank',
              bank_account_full_name: 'Bobtail Bank Full Name',
              bankInfo: {
                account: '123456789012'
              },
              confirmed_wire: true,
              modern_treasury_external_account_id: 14324524,
              plaid_verification_status: 'pending_manual_verification',
              status: null,
            }"
            :prevent-click="true"
            :user-is-client="true"
          />
        </div>

        <div
          v-if="requiresWireRoutingNumber"
          class="BasePersonalizedBankingModal__help-bank"
        >
          <label class="row fw-med mb-8">Requires Wire Routing Number</label>
          <p class="fs-14 row mb-8">
            Requires wire routing number indicates that our bank service provider needs an
            updated wire routing number for your account. Many banks have multiple routing
            numbers, so if you see this, please click "ADD WIRE ROUTING NUMBER" and enter the
            routing number associated with this account.
          </p>
          <base-bank
            :bank-account="{
              bank_name: 'Bobtail Bank',
              bank_account_full_name: 'Bobtail Bank Full Name',
              bankInfo: {
                account: '123456789012'
              },
              confirmed_wire: false,
              modern_treasury_external_account_id: 14324524,
              plaid_verification_status: null,
              status: null,
            }"
            :prevent-click="true"
            :user-is-client="true"
          />
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <base-button
        @click="$emit('close')"
        class="bg-light fc-white mt-24"
        data-cy="personalized-bank-modal-close-btn"
      >
        Close
      </base-button>
    </template>
  </base-modal>
</template>

<script>
// Components
import BaseBank from './base-bank.vue'
import BaseButton from './base-button.vue'
import BaseModal from './base-modal.vue'

export default {
  name: 'BasePersonalizedBankingModal',

  components: {
    BaseBank,
    BaseButton,
    BaseModal,
  },

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    noName: {
      type: Boolean,
      required: false,
      default: false,
    },
    pendingVerification: {
      type: Boolean,
      required: false,
      default: false,
    },
    pendingMicroDeposits: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiresWireRoutingNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    verificationExpired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.BasePersonalizedBankingModal

  &__help-bank
    margin-bottom: rem(48px)

  &__scrolling-help-content
    overflow-y: auto
</style>
