var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DashboardClientBanking column" },
    [
      _vm.activeBankVerificationExpired
        ? _c("base-alert", {
            staticClass: "mb-24",
            attrs: { "disable-close": true },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function() {
                    return [
                      _c("label", { staticClass: "fc-white" }, [
                        _vm._v(
                          "\n        Your active bank information has expired. Please update immediately so your funds are not delayed.\n      "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              417995775
            )
          })
        : _vm._e(),
      _c("label", { staticClass: "fs-16 fw-med" }, [_vm._v("Bank Accounts")]),
      _c("label", { staticClass: "fc-light fs-14 mb-24" }, [
        _vm._v(
          "\n    This is where we show the bank account(s) you have linked to Bobtail.\n    "
        ),
        _c(
          "a",
          {
            staticClass: "fc-blue fs-14",
            attrs: { "data-cy": "personalized-modal-button" },
            on: {
              click: function($event) {
                return _vm.$set(
                  _vm.personalizedExplanationModal,
                  "visible",
                  true
                )
              }
            }
          },
          [_vm._v("\n      Click here\n    ")]
        ),
        _vm._v(
          "\n    to learn more about why your bank account looks like the way it does.\n  "
        )
      ]),
      _c(
        "div",
        { staticClass: "row row--wrap mb-24" },
        [
          _vm.userIsClient
            ? _c(
                "base-button",
                {
                  staticClass: "bg-green fc-white mr-10",
                  attrs: { "data-cy": "add-bank-button" },
                  on: {
                    click: function($event) {
                      _vm.bankCreateVisible = true
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-add-circle fa-15" }),
                  _vm._v("\n      New Bank Account\n    ")
                ]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass:
                "DashboardClientBanking__archive-btn bg-trans fc-blue",
              on: { click: _vm.toggleHiddenBanksCallback }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(!_vm.showArchivedBanks ? "Show" : "Hide") +
                  " Archived Banks\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm.banks.length
        ? _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "row row--wrap", attrs: { "data-cy": "banks" } },
              _vm._l(_vm.banks, function(bank) {
                return _c("base-bank", {
                  key: bank.id,
                  attrs: {
                    "bank-account": bank,
                    "data-cy": "base-bank-" + bank.id,
                    "user-is-client": _vm.userIsClient
                  },
                  on: {
                    "bank-updated": _vm.getBanks,
                    "done-adding-bank": function($event) {
                      _vm.loading = false
                    },
                    "started-adding-bank": function($event) {
                      _vm.loading = true
                    }
                  }
                })
              }),
              1
            )
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
        _vm.loading
          ? _c(
              "div",
              {
                staticClass:
                  "\n        DashboardClientBanking__loading-indicator-container row row--align-center\n        row--justify-center\n      "
              },
              [_c("i", { staticClass: "fa fa-processing fa-spin fs-60" })]
            )
          : _vm._e()
      ]),
      _vm.personalizedExplanationModal.visible
        ? _c("base-personalized-banking-modal", {
            attrs: {
              active: _vm.personalizedExplanationModal.active,
              "no-name": _vm.personalizedExplanationModal.noName,
              "pending-verification":
                _vm.personalizedExplanationModal.pendingVerification,
              "pending-micro-deposits":
                _vm.personalizedExplanationModal.pendingMicroDeposits,
              "requires-wire-routing-number":
                _vm.personalizedExplanationModal.requiresWireRoutingNumber,
              "verification-expired":
                _vm.personalizedExplanationModal.verificationExpired
            },
            on: {
              close: function($event) {
                _vm.personalizedExplanationModal.visible = false
              }
            }
          })
        : _vm._e(),
      _vm.bankCreateVisible
        ? _c("base-bank-create", {
            attrs: { "client-id": _vm.id },
            on: {
              "bank-added": _vm.bankAddedCallback,
              close: function($event) {
                _vm.bankCreateVisible = false
              },
              "done-adding-bank": function($event) {
                _vm.loading = false
              },
              "started-adding-bank": function($event) {
                _vm.loading = true
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }