var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-modal", {
    staticClass: "BasePersonalizedBankingModal",
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [_vm._v("Why does your bank account look like that?")]
        },
        proxy: true
      },
      {
        key: "input",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "BasePersonalizedBankingModal__scrolling-help-content"
              },
              [
                _c("p", { staticClass: "fs-14" }, [
                  _vm._v(
                    '\n        A bank account can have multiple different "states" and depending on the state, you may\n        need to do something to get your bank account active and accepting funds from Bobtail.\n      '
                  )
                ]),
                _c("hr", { staticClass: "Divider mb-24 mt-24" }),
                _vm.active
                  ? _c(
                      "div",
                      {
                        staticClass: "BasePersonalizedBankingModal__help-bank"
                      },
                      [
                        _c("label", { staticClass: "row fw-med mb-8" }, [
                          _vm._v("Active")
                        ]),
                        _c("p", { staticClass: "fs-14 row mb-8" }, [
                          _vm._v(
                            "\n          If you're bank looks like this, then you're ready to accept money from Bobtail!\n        "
                          )
                        ]),
                        _c("base-bank", {
                          attrs: {
                            "bank-account": {
                              bank_name: "Bobtail Bank",
                              bank_account_full_name: "Bobtail Bank Full Name",
                              bankInfo: {
                                account: "123456789012"
                              },
                              confirmed_wire: true,
                              modern_treasury_external_account_id: 14324524,
                              plaid_verification_status: null,
                              status: "active"
                            },
                            "prevent-click": true,
                            "user-is-client": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.noName
                  ? _c(
                      "div",
                      {
                        staticClass: "BasePersonalizedBankingModal__help-bank"
                      },
                      [
                        _c("label", { staticClass: "row fw-med mb-8" }, [
                          _vm._v("No Name on Account")
                        ]),
                        _c("p", { staticClass: "fs-14 row mb-8" }, [
                          _vm._v(
                            '\n          If your bank looks like this, for some reason, we didn\'t receive the name on your\n          account and you can\'t receive wire transfers.\n          First, in your banking dashboard, click on the bank then click "edit account details."\n          Second, input the exact name on your bank account in the "name on account" input field.\n          Lastly, click update.\n        '
                          )
                        ]),
                        _c("base-bank", {
                          attrs: {
                            "bank-account": {
                              bank_account_full_name: null,
                              bankInfo: {
                                account: "123456789012"
                              },
                              bank_name: "Bobtail Bank",
                              confirmed_wire: true,
                              modern_treasury_external_account_id: 14324524,
                              plaid_verification_status: null,
                              status: null
                            },
                            "prevent-click": true,
                            "user-is-client": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.pendingVerification
                  ? _c(
                      "div",
                      {
                        staticClass: "BasePersonalizedBankingModal__help-bank"
                      },
                      [
                        _c("label", { staticClass: "row fw-med mb-8" }, [
                          _vm._v("Pending Verification")
                        ]),
                        _c("p", { staticClass: "fs-14 row mb-8" }, [
                          _vm._v(
                            "\n          Pending verification means your bank is undergoing an automated verificatino process.\n          Check back later and you'll most likely find it updated!\n        "
                          )
                        ]),
                        _c("base-bank", {
                          attrs: {
                            "bank-account": {
                              bank_name: "Bobtail Bank",
                              bank_account_full_name: "Bobtail Bank Full Name",
                              bankInfo: {
                                account: "123456789012"
                              },
                              confirmed_wire: true,
                              modern_treasury_external_account_id: 14324524,
                              plaid_verification_status:
                                "pending_automatic_verification",
                              status: null
                            },
                            "prevent-click": true,
                            "user-is-client": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.verificationExpired
                  ? _c(
                      "div",
                      {
                        staticClass: "BasePersonalizedBankingModal__help-bank"
                      },
                      [
                        _c("label", { staticClass: "row fw-med mb-8" }, [
                          _vm._v("Verification Expired")
                        ]),
                        _c("p", { staticClass: "fs-14 row mb-8" }, [
                          _vm._v(
                            '\n          Verification expired means your bank requires you to re-authenticate your bank\n          account every so often, so if your bank looks like this, click the "update" button on\n          the bank account.\n        '
                          )
                        ]),
                        _c("base-bank", {
                          attrs: {
                            "bank-account": {
                              bank_name: "Bobtail Bank",
                              bank_account_full_name: "Bobtail Bank Full Name",
                              bankInfo: {
                                account: "123456789012"
                              },
                              confirmed_wire: true,
                              modern_treasury_external_account_id: 14324524,
                              plaid_verification_status: "verification_expired",
                              status: null
                            },
                            "prevent-click": true,
                            "user-is-client": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.pendingMicroDeposits
                  ? _c(
                      "div",
                      {
                        staticClass: "BasePersonalizedBankingModal__help-bank"
                      },
                      [
                        _c("label", { staticClass: "row fw-med mb-8" }, [
                          _vm._v("Pending Micro-deposits")
                        ]),
                        _c("p", { staticClass: "fs-14 row mb-8" }, [
                          _vm._v(
                            '\n          Pending micro-deposits means Plaid has sent you two very small transactions (less than $1) to verify the routing numbers of your account. First, do not guess these amounts. It will lock out your account permanently if you fail three times. Second, you need to find those transaction amounts in your bank transactions. Third, within your banking dashboard, click on the respective bank and click "Verify Micro-deposits" and enter the amounts.\n        '
                          )
                        ]),
                        _c("base-bank", {
                          attrs: {
                            "bank-account": {
                              bank_name: "Bobtail Bank",
                              bank_account_full_name: "Bobtail Bank Full Name",
                              bankInfo: {
                                account: "123456789012"
                              },
                              confirmed_wire: true,
                              modern_treasury_external_account_id: 14324524,
                              plaid_verification_status:
                                "pending_manual_verification",
                              status: null
                            },
                            "prevent-click": true,
                            "user-is-client": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.requiresWireRoutingNumber
                  ? _c(
                      "div",
                      {
                        staticClass: "BasePersonalizedBankingModal__help-bank"
                      },
                      [
                        _c("label", { staticClass: "row fw-med mb-8" }, [
                          _vm._v("Requires Wire Routing Number")
                        ]),
                        _c("p", { staticClass: "fs-14 row mb-8" }, [
                          _vm._v(
                            '\n          Requires wire routing number indicates that our bank service provider needs an\n          updated wire routing number for your account. Many banks have multiple routing\n          numbers, so if you see this, please click "ADD WIRE ROUTING NUMBER" and enter the\n          routing number associated with this account.\n        '
                          )
                        ]),
                        _c("base-bank", {
                          attrs: {
                            "bank-account": {
                              bank_name: "Bobtail Bank",
                              bank_account_full_name: "Bobtail Bank Full Name",
                              bankInfo: {
                                account: "123456789012"
                              },
                              confirmed_wire: false,
                              modern_treasury_external_account_id: 14324524,
                              plaid_verification_status: null,
                              status: null
                            },
                            "prevent-click": true,
                            "user-is-client": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c(
              "base-button",
              {
                staticClass: "bg-light fc-white mt-24",
                attrs: { "data-cy": "personalized-bank-modal-close-btn" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("\n      Close\n    ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }