<template>
  <div class="BaseBankCreate">
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <plaid-link
        v-if="plaidLinkVisible"
        @adding-troublesome-bank="troublesomeBank = $event"
        @bank-added="addBank"
      />

      <!-- Verify Plaid returned bank details -->
      <!--
        VERY IMPORTANT DETAIL: we MUST POST the bank to have access to the name on account
        and wire routing number to hydrate the update-bank-modal, so we have to ensure
        :editing="false" to prevent the user from skipping this step
      -->
      <update-bank-modal
        v-if="showUpdateBankModal"
        @bank-updated="$emit('bank-added')"
        @close="$emit('close')"
        :bank="bank"
        :editing="false"
        :troublesome-bank="troublesomeBank"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import { ClientBankAccount } from '../utils/api'
// Components
import PlaidLink from './plaid-link.vue'
import UpdateBankModal from './update-bank-modal.vue'

export default {
  name: 'BaseBankCreate',

  components: {
    PlaidLink,
    UpdateBankModal,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      bank: null,
      bankAccountOwnerName: {
        valid: true,
        value: '',
      },
      plaidLinkVisible: true,
      showUpdateBankModal: false,
      troublesomeBank: null,
    }
  },

  methods: {
    async addBank (accountResponse) {
      this.disabled = true
      // Triggers loading indicator in dashboard-banking
      this.$emit('started-adding-bank')
      this.progressStart()

      try {
        this.bank = (await ClientBankAccount.create({
          bank_account_name: accountResponse.subtype,
          bank_name: accountResponse.institution.name,
          client_id: this.clientId,
          plaid_link_session_id: accountResponse.link_session_id,
          plaid_verification_status: accountResponse.account.verification_status,
          plaid_account_id: accountResponse.account.id,
          plaid_public_token: accountResponse.public_token,
        })).data
        this.disabled = false
        this.plaidLinkVisible = false
        this.$store.commit('STORE_CLIENT_WITHOUT_BANK_MODAL', false)
        // If the bank is a manual addition, we don't want to show the update modal
        // TODO: This is only focused on MD's required, but may cause issues with auto verification
        if (this.bank.plaid_verification_status === null) this.showUpdateBankModal = true
        else this.$emit('bank-added')
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Bank Account Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.disabled = false
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating a client bank account' })
      }

      // Kills loading indicator in dashboard-banking
      this.$emit('done-adding-bank')
    },

    cancel () {
      Object.assign(this.$data, this.$options.data())
    },
  },
}
</script>